import React from "react";

import Layout from "../layouts/en";
import SEO from "../components/seo";
import CompleteProfile from "../components/TWComponents/CompleteProfile";
import completeProfileLangData from "../data/static-pages/pages/complete-profile/en";

const CompleteProfilePage = (props) => {
  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Complete Profile "
        keyword="Complete Profile"
        decription="Complete Profile"
      />
      <CompleteProfile langData={completeProfileLangData} />
    </Layout>
  );
};

export default CompleteProfilePage;
